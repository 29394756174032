import React from "react";
import theme from "theme";
import { Theme, Text, Section, Button, Icon, Box, Image, Em } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { FaCheckCircle } from "react-icons/fa";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Дешевий ремонт авто
			</title>
			<meta name={"description"} content={"Економічне рішення для вашого автомобіля"} />
			<meta property={"og:title"} content={"Дешевий ремонт авто"} />
			<meta property={"og:description"} content={"Економічне рішення для вашого автомобіля"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65c9e4261d712a0023300924/images/coche-viejo-coche-nuevo.jpg?v=2024-02-12T09:42:46.450Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65c9e4261d712a0023300924/images/coche-viejo-coche-nuevo.jpg?v=2024-02-12T09:42:46.450Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65c9e4261d712a0023300924/images/coche-viejo-coche-nuevo.jpg?v=2024-02-12T09:42:46.450Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65c9e4261d712a0023300924/images/coche-viejo-coche-nuevo.jpg?v=2024-02-12T09:42:46.450Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65c9e4261d712a0023300924/images/coche-viejo-coche-nuevo.jpg?v=2024-02-12T09:42:46.450Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65c9e4261d712a0023300924/images/coche-viejo-coche-nuevo.jpg?v=2024-02-12T09:42:46.450Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65c9e4261d712a0023300924/images/coche-viejo-coche-nuevo.jpg?v=2024-02-12T09:42:46.450Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="text" />
			<Override slot="text4" />
			<Override slot="text2" />
			<Override slot="text1" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="box1" />
			<Override slot="link3" />
			<Override slot="text3" />
			<Override slot="link4" />
			<Override slot="icon" />
		</Components.Header>
		<Section padding="180px 0 184px 0" background="linear-gradient(180deg,rgba(11, 15, 59, 0.81) 0%,rgba(11, 15, 59, 0.7) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/65c9e4261d712a0023300924/images/coche-viejo-coche-nuevo.jpg?v=2024-02-12T09:42:46.450Z) 0% 0% /cover repeat scroll padding-box" sm-padding="120px 0 124px 0" quarkly-title="Hero-15">
			<Text
				margin="0px 0px 30px 0px"
				text-align="center"
				color="--light"
				font="normal 400 18px/1.5 --fontFamily-sans"
				letter-spacing="1px"
			>
				Економічне рішення для вашого автомобіля
			</Text>
			<Text
				margin="0px 0px 50px 0px"
				text-align="center"
				font="normal 700 64px/1.2 --fontFamily-sans"
				letter-spacing="1px"
				color="#ff436d"
				padding="0px 250px 0px 250px"
				lg-padding="0px 0 0px 0"
				md-font="normal 700 42px/1.2 --fontFamily-sans"
				sm-font="normal 700 36px/1.2 --fontFamily-sans"
				text-transform="uppercase"
			>
				ДЕШЕВИЙ РЕМОНТ АВТО
			</Text>
			<Text
				margin="0px 0px 0px 0px"
				color="#c8ced8"
				text-align="center"
				padding="0px 250px 0px 250px"
				font="--lead"
				lg-padding="0px 0 0px 0"
			>
				Ваш перший вибір, коли мова йде про високоякісні послуги з ремонту автомобілів за доступними цінами.
			</Text>
		</Section>
		<Section padding="80px 0 90px 0" quarkly-title="Headline-5" lg-padding="2rem 0 1rem 0">
			<Override
				slot="SectionContent"
				padding="0px 20% 0px 0px"
				lg-padding="0px 30% 0px 0px"
				md-padding="0px 0 0px 0px"
				sm-min-width="280px"
			/>
			<Text
				margin="0px 0 40px 0px"
				font="--headline3"
				color="#090e28"
				letter-spacing="4px"
				md-font="normal 600 56px/1.2 --fontFamily-sans"
				sm-font="normal 600 42px/1.2 --fontFamily-sans"
			>
				Завдяки багаторічному досвіду роботи в галузі наша віддана команда професійних механіків гарантує, що ваш автомобіль завжди буде в ідеальному стані.
			</Text>
			<Text margin="0px 0px 50px 0px" font="--base" color="#334455" sm-margin="0px 0px 25px 0px">
				Ми розуміємо цінність вашого автомобіля для вас, незалежно від того, чи це основний засіб пересування чи особистий спокій, і саме тому ми пропонуємо економічні рішення без зобов’язань щодо якості.
			</Text>
			<Button type="link" href="/about" text-align="center" text-decoration-line="initial">
				Детальніше про нас
			</Button>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Box display="grid" grid-template-columns="repeat(3, 1fr)" grid-gap="24px 4%" md-grid-template-columns="1fr">
				<Box justify-content="flex-start" flex-direction="column" display="flex">
					<Icon
						category="fa"
						icon={FaCheckCircle}
						margin="0px 0px 16px 0px"
						color="--dark"
						size="48px"
						padding="6px 6px 6px 6px"
					/>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Доступна якість
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Ми розуміємо, що кожен автомобіль і кожен клієнт мають унікальні потреби. Тому ми пропонуємо індивідуальний підхід до кожної послуги. Наша команда витрачає час, щоб зрозуміти ваші конкретні потреби та запропонувати індивідуальні рішення.
					</Text>
				</Box>
				<Box justify-content="flex-start" flex-direction="column" display="flex">
					<Icon
						category="fa"
						icon={FaCheckCircle}
						margin="0px 0px 16px 0px"
						color="--dark"
						size="48px"
						padding="6px 6px 6px 6px"
					/>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Персоналізована увага
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Ми знаємо, що ваш час цінний. Тому ReparAuto Baratos прагне надавати швидкі послуги без шкоди для якості. Наш офіс оснащений високоякісними інструментами та технологіями, що дозволяє нам ефективно діагностувати та ремонтувати ваш автомобіль.
					</Text>
				</Box>
				<Box justify-content="flex-start" flex-direction="column" display="flex">
					<Icon
						category="fa"
						icon={FaCheckCircle}
						margin="0px 0px 16px 0px"
						color="--dark"
						size="48px"
						padding="6px 6px 6px 6px"
					/>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Швидкість і ефективність
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						У ReparAuto Baratos ми прагнемо пропонувати винятковий сервіс, справедливі ціни та чесне та прозоре ставлення до всіх взаємодій. Виберіть нас, щоб подбати про ваш автомобіль і відчути відмінність відданості та турботи, яку ми можемо зробити.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-7">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
			>
				<Box
					overflow-x="hidden"
					transform="translateY(0px)"
					padding="0px 0px 100% 0px"
					width="100%"
					overflow-y="hidden"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					height="auto"
				>
					<Image
						src="https://uploads.quarkly.io/65c9e4261d712a0023300924/images/Los-10-autos-mas-baratos-de-mantener-y-reparar-2023.webp?v=2024-02-12T09:42:46.449Z"
						object-fit="cover"
						position="absolute"
						width="100%"
						bottom={0}
						display="block"
						top={0}
						left={0}
						right={0}
						min-height="100%"
						srcSet="https://smartuploads.quarkly.io/65c9e4261d712a0023300924/images/Los-10-autos-mas-baratos-de-mantener-y-reparar-2023.webp?v=2024-02-12T09%3A42%3A46.449Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65c9e4261d712a0023300924/images/Los-10-autos-mas-baratos-de-mantener-y-reparar-2023.webp?v=2024-02-12T09%3A42%3A46.449Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65c9e4261d712a0023300924/images/Los-10-autos-mas-baratos-de-mantener-y-reparar-2023.webp?v=2024-02-12T09%3A42%3A46.449Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65c9e4261d712a0023300924/images/Los-10-autos-mas-baratos-de-mantener-y-reparar-2023.webp?v=2024-02-12T09%3A42%3A46.449Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65c9e4261d712a0023300924/images/Los-10-autos-mas-baratos-de-mantener-y-reparar-2023.webp?v=2024-02-12T09%3A42%3A46.449Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65c9e4261d712a0023300924/images/Los-10-autos-mas-baratos-de-mantener-y-reparar-2023.webp?v=2024-02-12T09%3A42%3A46.449Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65c9e4261d712a0023300924/images/Los-10-autos-mas-baratos-de-mantener-y-reparar-2023.webp?v=2024-02-12T09%3A42%3A46.449Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				flex-wrap="wrap"
				flex-direction="row"
				lg-align-items="center"
				padding="4px 4px 4px 4px"
				lg-order="1"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				width="50%"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
			>
				<Box width="50%" padding="12px 12px 12px 13px" display="flex">
					<Box
						width="100%"
						height="auto"
						position="relative"
						transition="transform 0.2s ease-in-out 0s"
						margin="0px 0px 0px 0px"
						overflow-x="hidden"
						overflow-y="hidden"
						transform="translateY(0px)"
						hover-transform="translateY(-10px)"
						padding="0px 0px 100% 0px"
					>
						<Image
							top="auto"
							left={0}
							right={0}
							bottom="0px"
							display="block"
							object-fit="cover"
							position="absolute"
							width="100%"
							min-height="100%"
							src="https://uploads.quarkly.io/65c9e4261d712a0023300924/images/617867d1222a0554294275bd_Reparacion-de-Autos-chocados-v001-1024x518.jpeg?v=2024-02-12T09:42:46.466Z"
							srcSet="https://smartuploads.quarkly.io/65c9e4261d712a0023300924/images/617867d1222a0554294275bd_Reparacion-de-Autos-chocados-v001-1024x518.jpeg?v=2024-02-12T09%3A42%3A46.466Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65c9e4261d712a0023300924/images/617867d1222a0554294275bd_Reparacion-de-Autos-chocados-v001-1024x518.jpeg?v=2024-02-12T09%3A42%3A46.466Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65c9e4261d712a0023300924/images/617867d1222a0554294275bd_Reparacion-de-Autos-chocados-v001-1024x518.jpeg?v=2024-02-12T09%3A42%3A46.466Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65c9e4261d712a0023300924/images/617867d1222a0554294275bd_Reparacion-de-Autos-chocados-v001-1024x518.jpeg?v=2024-02-12T09%3A42%3A46.466Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65c9e4261d712a0023300924/images/617867d1222a0554294275bd_Reparacion-de-Autos-chocados-v001-1024x518.jpeg?v=2024-02-12T09%3A42%3A46.466Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65c9e4261d712a0023300924/images/617867d1222a0554294275bd_Reparacion-de-Autos-chocados-v001-1024x518.jpeg?v=2024-02-12T09%3A42%3A46.466Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65c9e4261d712a0023300924/images/617867d1222a0554294275bd_Reparacion-de-Autos-chocados-v001-1024x518.jpeg?v=2024-02-12T09%3A42%3A46.466Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Box>
				</Box>
				<Box padding="12px 12px 12px 12px" display="flex" width="50%">
					<Box
						transition="transform 0.2s ease-in-out 0s"
						hover-transform="translateY(-10px)"
						position="relative"
						transform="translateY(0px)"
						overflow-x="hidden"
						overflow-y="hidden"
						margin="0px 0px 0px 0px"
						padding="0px 0px 100% 0px"
						width="100%"
						height="auto"
					>
						<Image
							bottom="0px"
							src="https://uploads.quarkly.io/65c9e4261d712a0023300924/images/3-4.jpg?v=2024-02-12T09:42:20.827Z"
							position="absolute"
							top="auto"
							left={0}
							min-height="100%"
							object-fit="cover"
							display="block"
							width="100%"
							right={0}
							srcSet="https://smartuploads.quarkly.io/65c9e4261d712a0023300924/images/3-4.jpg?v=2024-02-12T09%3A42%3A20.827Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65c9e4261d712a0023300924/images/3-4.jpg?v=2024-02-12T09%3A42%3A20.827Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65c9e4261d712a0023300924/images/3-4.jpg?v=2024-02-12T09%3A42%3A20.827Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65c9e4261d712a0023300924/images/3-4.jpg?v=2024-02-12T09%3A42%3A20.827Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65c9e4261d712a0023300924/images/3-4.jpg?v=2024-02-12T09%3A42%3A20.827Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65c9e4261d712a0023300924/images/3-4.jpg?v=2024-02-12T09%3A42%3A20.827Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65c9e4261d712a0023300924/images/3-4.jpg?v=2024-02-12T09%3A42%3A20.827Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Box>
				</Box>
				<Box width="50%" padding="12px 12px 12px 12px" display="flex">
					<Box
						margin="0px 0px 0px 0px"
						padding="0px 0px 100% 0px"
						transition="transform 0.2s ease-in-out 0s"
						height="auto"
						overflow-x="hidden"
						overflow-y="hidden"
						position="relative"
						transform="translateY(0px)"
						hover-transform="translateY(-10px)"
						width="100%"
					>
						<Image
							object-fit="cover"
							display="block"
							top="auto"
							right={0}
							bottom="0px"
							min-height="100%"
							src="https://uploads.quarkly.io/65c9e4261d712a0023300924/images/156605-manutencao-do-carro-o-que-voce-precisa-para-dirigir-com-seguranca.jpg?v=2024-02-12T09:42:46.446Z"
							position="absolute"
							width="100%"
							left={0}
							srcSet="https://smartuploads.quarkly.io/65c9e4261d712a0023300924/images/156605-manutencao-do-carro-o-que-voce-precisa-para-dirigir-com-seguranca.jpg?v=2024-02-12T09%3A42%3A46.446Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65c9e4261d712a0023300924/images/156605-manutencao-do-carro-o-que-voce-precisa-para-dirigir-com-seguranca.jpg?v=2024-02-12T09%3A42%3A46.446Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65c9e4261d712a0023300924/images/156605-manutencao-do-carro-o-que-voce-precisa-para-dirigir-com-seguranca.jpg?v=2024-02-12T09%3A42%3A46.446Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65c9e4261d712a0023300924/images/156605-manutencao-do-carro-o-que-voce-precisa-para-dirigir-com-seguranca.jpg?v=2024-02-12T09%3A42%3A46.446Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65c9e4261d712a0023300924/images/156605-manutencao-do-carro-o-que-voce-precisa-para-dirigir-com-seguranca.jpg?v=2024-02-12T09%3A42%3A46.446Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65c9e4261d712a0023300924/images/156605-manutencao-do-carro-o-que-voce-precisa-para-dirigir-com-seguranca.jpg?v=2024-02-12T09%3A42%3A46.446Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65c9e4261d712a0023300924/images/156605-manutencao-do-carro-o-que-voce-precisa-para-dirigir-com-seguranca.jpg?v=2024-02-12T09%3A42%3A46.446Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Box>
				</Box>
				<Box width="50%" padding="12px 12px 12px 12px" display="flex">
					<Box
						position="relative"
						hover-transform="translateY(-10px)"
						padding="0px 0px 100% 0px"
						transform="translateY(0px)"
						transition="transform 0.2s ease-in-out 0s"
						margin="0px 0px 0px 0px"
						width="100%"
						height="auto"
						overflow-x="hidden"
						overflow-y="hidden"
					>
						<Image
							src="https://uploads.quarkly.io/65c9e4261d712a0023300924/images/3-1.jpg?v=2024-02-12T09:42:20.822Z"
							position="absolute"
							display="block"
							top="auto"
							left={0}
							min-height="100%"
							object-fit="cover"
							width="100%"
							right={0}
							bottom="0px"
							srcSet="https://smartuploads.quarkly.io/65c9e4261d712a0023300924/images/3-1.jpg?v=2024-02-12T09%3A42%3A20.822Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65c9e4261d712a0023300924/images/3-1.jpg?v=2024-02-12T09%3A42%3A20.822Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65c9e4261d712a0023300924/images/3-1.jpg?v=2024-02-12T09%3A42%3A20.822Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65c9e4261d712a0023300924/images/3-1.jpg?v=2024-02-12T09%3A42%3A20.822Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65c9e4261d712a0023300924/images/3-1.jpg?v=2024-02-12T09%3A42%3A20.822Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65c9e4261d712a0023300924/images/3-1.jpg?v=2024-02-12T09%3A42%3A20.822Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65c9e4261d712a0023300924/images/3-1.jpg?v=2024-02-12T09%3A42%3A20.822Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Box>
				</Box>
			</Box>
			<Box
				width="100%"
				align-items="flex-start"
				lg-align-items="center"
				sm-margin="0px 0px 20px 0px"
				display="flex"
				flex-direction="column"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				lg-padding="0px 16px 16px 16px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--lead"
					lg-text-align="center"
					width="60%"
					lg-width="100%"
					md-text-align="left"
				>
					Тут, у ReparAuto Baratos, ми доводимо, що ви підтримуєте свій автомобіль у відмінному стані та не витрачаєте свій гаманець. Ми пропонуємо широкий спектр послуг, від профілактичного обслуговування до комплексного ремонту, все за конкурентними цінами. Наша місія полягає в тому, щоб забезпечити повну прозорість, надійність і задоволення клієнтів у кожній нашій роботі.
				</Text>
				<Text
					margin="1rem 0px 0px 0px"
					color="--darkL2"
					font="--headline3"
					lg-text-align="center"
					width="60%"
					lg-width="100%"
					md-text-align="left"
				>
					<Em>
						Завітайте до нас і дізнайтеся, як ми можемо допомогти вам забезпечити безперебійний рух вашого автомобіля, не завдаючи грошей.
					</Em>
				</Text>
			</Box>
		</Section>
		<Components.Header>
			<Override slot="link4">
				info@autobaratos.com
			</Override>
		</Components.Header>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65bb673d867c86001f1b379d"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});